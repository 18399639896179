import { Auth0Lock } from 'auth0-lock';

export interface LoginFormAppConfig {
    auth0: { clientID: string; domain: string; callbackUrl: string; connections?: string[] };
    redirectUrl?: string;
}

export interface LoginFormUrlSearchParams {
    prefill: Auth0LockConstructorOptions['prefill'];
    error?: UnauthorizedErrorMessage;
    redirectUrl?: string;
    initialScreen: Exclude<Auth0LockShowOptions['initialScreen'], 'signUp'>;
}

export type LoginFormConfig = LoginFormUrlSearchParams & LoginFormAppConfig;

const contactSupport = '<a href="mailto:support@42technologies.com">contact support</a>';
const pwdReset = "<a href='/initialScreen=forgotPassword'>reset it</a>";

class UnauthorizedErrorMessage {
    static readonly MESSAGE: string = `Unknown error<br>please try again later or ${contactSupport}`;

    static Create(data: unknown) {
        let msg: string | null;
        msg ??= typeof data !== 'string' || data.length === 0 ? null : decodeURIComponent(data);
        msg ??= UnauthorizedErrorMessage.MESSAGE;
        return msg.startsWith('PW_EXPIRED:') ? new PasswordExpiredErrorMessage(msg) : new UnauthorizedErrorMessage(msg);
    }

    public redirectUrl?: null | URL = null;
    constructor(public readonly message: string) {}
}

class PasswordExpiredErrorMessage extends UnauthorizedErrorMessage {
    static readonly MESSAGE: string = `It's time to change your password<br />please ${pwdReset} or ${contactSupport}`;
    constructor(message: string) {
        super(PasswordExpiredErrorMessage.MESSAGE);
        try {
            const rawUrl = message.split('PW_EXPIRED:')[1];
            const raw: unknown = JSON.parse(rawUrl);
            if (typeof raw !== 'string') throw new Error(`parse error for ticket url: ${message}`);
            this.redirectUrl = new URL(raw);
        } catch (error) {
            console.error(message);
            console.error('parse error for ticket url:', error);
        }
    }
}

const getErrorFromSearchParams = (params: URLSearchParams): undefined | UnauthorizedErrorMessage => {
    try {
        if (!params.get('error')) return;
        return UnauthorizedErrorMessage.Create(params.get('error_description'));
    } catch (error) {
        console.error(error);
        return;
    }
};

// This will be used by the auth proxy to redirect after login
const getRedirectUrlFromSearchParams = (params: URLSearchParams): string | undefined => {
    const result = params.get('redirect_url') ?? params.get('redirectUrl') ?? params.get('redirectURL');
    return result ?? undefined;
};

const getInitialScreenFromSearchParams = (params: URLSearchParams) => {
    const isValid = (x: unknown): x is LoginFormUrlSearchParams['initialScreen'] => {
        return typeof x === 'string' && ['login', 'forgotPassword'].includes(x);
    };
    const value = params.get('initial_screen') ?? params.get('initialScreen');
    return isValid(value) ? value : undefined;
};

const getPrefillFromSearchParams = (params: URLSearchParams): LoginFormUrlSearchParams['prefill'] => {
    const email = params.get('login_hint');
    if (!email) return {};
    return { email };
};

const getAppConfig = async (): Promise<LoginFormAppConfig> => {
    const response = await fetch('/config/config.json');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    if (response.ok) return await response.json();
    console.error(response);
    throw new Error('Could not get config due to error.');
};

const getRedirectUrl = (config: LoginFormAppConfig, redirectUrl?: string) => {
    try {
        if (!config.auth0.callbackUrl) {
            console.error('No callback URL configured.');
            return;
        }
        const authUrl = new URL(config.auth0.callbackUrl);
        // if we have one, we add it to the auth0 callback url, the auth proxy will send a redirect
        if (redirectUrl) authUrl.searchParams.set('redirect_url', redirectUrl);
        return authUrl.toString();
    } catch (error) {
        console.error(error);
        return;
    }
};

const getConfig = async (window: Window): Promise<LoginFormConfig> => {
    const appConfig = await getAppConfig();
    const params = new URL(window.location.href).searchParams;
    const redirectUrl = getRedirectUrl(appConfig, getRedirectUrlFromSearchParams(params));
    return {
        auth0: appConfig.auth0,
        prefill: { ...PrefillStorage.read(), ...getPrefillFromSearchParams(params) },
        initialScreen: getInitialScreenFromSearchParams(params),
        redirectUrl: redirectUrl,
        error: getErrorFromSearchParams(params),
    };
};

export const createLoginForm = async (window: Window, logoUrl: string) => {
    const config = await getConfig(window);
    console.log('[login-form]', 'config:', config);

    const lock = new Auth0Lock(config.auth0.clientID, config.auth0.domain, {
        container: 'login-form',
        avatar: null,
        closable: false,
        allowSignUp: false,
        rememberLastLogin: true,
        allowAutocomplete: false,
        allowShowPassword: true,
        usernameStyle: 'email',
        prefill: config.prefill,
        allowedConnections: config.auth0.connections,
        languageBaseUrl: 'https://auth0-cdn.42technologies.com',
        configurationBaseUrl: 'https://auth0-cdn.42technologies.com',
        theme: {
            logo: logoUrl,
            primaryColor: '#242a33',
        },
        auth: {
            responseType: 'code',
            redirectUrl: config.redirectUrl,
            sso: false,
        },
    });

    try {
        if (config.error?.redirectUrl) {
            console.log('[login-form]', 'error redirection url:', config.error.redirectUrl.toString());
            window.history.replaceState(null, '', window.location.origin);
            window.location.href = config.error.redirectUrl.toString();
            await new Promise(r => setTimeout(r, 5000));
            console.error('[login-form]', 'error redirection timeout:', config.error.redirectUrl.toString());
        }
    } catch (error) {
        console.error('[login-form]', error);
    }

    lock.show({
        initialScreen: config.initialScreen ?? 'login',
        flashMessage: config.error ? { type: 'error', text: config.error.message } : undefined,
    });

    window.history.replaceState(null, '', window.location.origin);
    void startEmailInputMonitor(config);
};

async function startEmailInputMonitor(config: LoginFormConfig) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, @typescript-eslint/strict-boolean-expressions
    if (!window.MutationObserver) return;

    const submitEl: HTMLElement = await (async () => {
        let el: HTMLElement | null = null;
        while (!(el = document.querySelector('.auth0-lock-submit'))) {
            await new Promise(r => setTimeout(r, 300));
        }
        return el;
    })();

    function checkIfIsOnLoginScreen() {
        const submitText = submitEl.innerText;
        return submitText.toUpperCase().includes('LOG IN');
    }

    const onEmailInputChange = debounce((x: string) => PrefillStorage.write(x), 250);
    const emailInputObserver = new MutationObserver(events => {
        for (const event of events) {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const el = event.target as HTMLElement;
            if (el.getAttribute('type') !== 'email') continue;
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (!el.classList?.contains('auth0-lock-input')) continue;
            return onEmailInputChange(el.getAttribute('value') ?? '');
        }
    });

    let isOnLoginScreen: boolean | null;
    function updateEmailObserver() {
        const current = checkIfIsOnLoginScreen();
        if (isOnLoginScreen === current) return;
        isOnLoginScreen = current;
        if (current) {
            console.log('[login-form]', 'starting email observer');
            const container = document.querySelector('.auth0-lock-input-email');
            if (!container) throw new Error('Could not find email input container.');
            emailInputObserver.observe(container, { attributes: true, childList: true, subtree: true });
        } else {
            console.log('[login-form]', 'stopping email observer');
            emailInputObserver.disconnect();
        }
    }

    const submitTextObserver = new MutationObserver(events => {
        updateEmailObserver();
    });
    submitTextObserver.observe(submitEl, { attributes: true, childList: true });
    updateEmailObserver();
}

const PrefillStorage = (() => {
    const key = '42.login-form.auth0lock.prefill.email';
    let state: null | string = null;
    return {
        read() {
            let email = localStorage.getItem(key);
            if (typeof email !== 'string') return {};
            email = email.substring(0, 256);
            state = email;
            return { email };
        },
        write(value: string) {
            value = value.substring(0, 256);
            if (value === state) return;
            console.log('[login-form]', 'setting prefill value:', value);
            localStorage.setItem(key, value);
            state = value;
        },
    };
})();

function debounce<T>(func: (...args: T[]) => void, timeout = 100) {
    let timer: ReturnType<typeof setTimeout>;
    return (...args: T[]) => {
        clearTimeout(timer);
        timer = setTimeout(() => func(...args), timeout);
    };
}

function createDebugPanel() {
    const container = document.createElement('div');
    container.style.position = 'fixed';
    container.style.top = '0';
    container.style.right = '0';
    container.style.zIndex = '9999';
    container.style.backgroundColor = 'white';
    container.style.padding = '1rem';
    container.style.margin = '1rem';
    container.style.border = '2px solid black';
    container.style.borderRadius = '.5rem';
    container.style.display = 'flex';
    container.style.flexDirection = 'column';
    container.style.gap = '1rem';
    document.body.appendChild(container);

    const header = document.createElement('h1');
    header.innerText = 'Development Panel';
    header.style.textTransform = 'uppercase';
    header.style.letterSpacing = '1px';
    header.style.fontSize = '.8rem';
    container.appendChild(header);

    const aErrorNoMsg = document.createElement('a');
    aErrorNoMsg.href = '/?error=unauthorized';
    aErrorNoMsg.innerText = 'Error (fallback message)';
    container.appendChild(aErrorNoMsg);

    const aErrorWithMsg = document.createElement('a');
    aErrorWithMsg.href = '/?error=unauthorized&error_description=Some%20error%20message';
    aErrorWithMsg.innerText = 'Error (custom message)';
    container.appendChild(aErrorWithMsg);

    const aPwExpiredError = document.createElement('a');
    const expiryUrl = new URL('/', window.location.origin);
    expiryUrl.searchParams.set('error', 'unauthorized');
    expiryUrl.searchParams.set(
        'error_description',
        `PW_EXPIRED:${JSON.stringify('https://auth0.42technologies.com/u/reset-password?ticket=1234567890')}`,
    );
    aPwExpiredError.href = expiryUrl.toString();
    aPwExpiredError.innerText = 'Error (pw expired)';
    container.appendChild(aPwExpiredError);
}

// if (!window.location.origin.endsWith('.42technologies.com')) {
//     createDebugPanel();
// }
